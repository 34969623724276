

// Tabs
.hidden-tab {
  // this alowes slick slider to still calc the size of the slides in the hidden tabs.
  height: 0;
  overflow: hidden;
}

.tabs-section {
  margin-bottom: 70px;
  margin-top: 40px;
  .title-b {
    padding: 0 0 30px;
  }
  .tabs {
    display: flex;
    text-align: center;
  }
  .tabs  {
    li{
      display: inline-block;
      font-weight: 600;
      min-width: 100px;
      width: 100%;
      font-size: 1rem;
      text-transform: uppercase;
      color: $white-c;
      height: 52px;
      line-height: 52px;
      position: relative;
      a {
        background-color: #8797B4;
        color: $white-c;
        display: block;
        text-align: center;
        text-decoration: none;
        &.active {
          background-color: #4B4B4D;
          &:before {
            content: '';
            display: inline-block;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: 10px solid #4B4B4D;
            position: absolute;
            top: 100%;
            left: 50%;
          }
          &:hover{
            background-color: #4B4B4D;
          }
        }
        &:hover{
          background-color: #777777;
        }
      }
    }


  }
  .tabgroup{
    padding: 30px 0 0;
    .text-block{


      .title-s{
        color: $brown;
        font-size: 15px; padding-bottom: 60px;

        &:after{bottom:30px; transform: scaleX(1); transition: transform .7s;}

        a{transition: .7s ease-in-out;}
      }
      .services-btn {
        width: 100%;
        text-align: center;
        height: 52px;
        line-height: 52px;
        font-size: 0.8125rem;
        background-color: #ECEDED;
        color: $black-c;
        text-transform: uppercase;
        font-weight: 600;
        @include transition-all(.4s);
        &:hover{
          @include transition-all(.4s);
          background-color: $blue;
          color: $white-c;
        }
      }
    }

    p{
      font-size: 0.9375rem;
      color: #777; text-align: center;
    }

    .service{
      margin-bottom: 30px;

      > a{
        padding: 0 0 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        height:274px;
        color: $brown;
        img{
          width: 100%;
          height: 100%;
        }
      }


      &:hover .title-s:after{transform: scaleX(1.5);}

    }

    .service:hover h3{
      color: $white-c;
      background-color: $blue;
      @include transition-all(.4s);
    }
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
}

.slide-header {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}