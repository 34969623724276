/* News - Home Latest News, List News & Article */

/* Home List News*/
.latest-news{
  margin-bottom: /*$block-margin-bottom*/ 70px;
  .image-block{
    position: relative;
  }
  .about {
    width: 115px;
    border: 2px solid $red-c;
    background-color: $white-c;
    font-weight: 700;
    text-align: center;
    padding: 19px 0 19px 0;
    text-transform: uppercase;
    position: absolute;
    left: 20px; bottom: 10px;
    @include transition-all(0.4s);
    .date {
      display: block;
      font-size: 12px;
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:before {
        content: '';
        position: absolute;
        left: 50%; bottom: 0;
        @include translate(-50%, 0);
        width: 30px; height: 1px;
        background-color: $border-c;
      }
    }
    .comments-qty {
      display: block;
      font-size: 10px;
    }
  }
  .btn-tr{
    background-color: $blue;
    border: 1px solid $blue;
    font-size: 0.6875rem;
  }
}

.news{

  .text_block{
    padding: 20px 0;

    .date_author_comments{
      display: inline-block; width: 100%;
      border-bottom: 1px solid #dddddd;
      color: #777;
      padding-bottom: 7px;
      margin-bottom: 10px;

      >div{display: inline-block;}

      span{ padding-right: 7px; }

      .date{ padding-right: 20px; }
      .author{ }
      .comments{ }
    }

    h2, h3{
      font-weight: bold; font-size: 1rem;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .short_text {
      height: 68px;
      display: table-cell;
      vertical-align: middle;
      width: 100%;
    }
  }

  &:hover{
    img{opacity: 0.8; @include transition-all(.4s);}

    h2 a, h3 a{color: $blue; @include transition-all(.4s);}
  }
  .image-block{
    position: relative;
  }
  .about {
    width: 115px;
    border: 2px solid $red-c;
    background-color: $white-c;
    font-weight: 700;
    text-align: center;
    padding: 19px 0 19px 0;
    text-transform: uppercase;
    position: absolute;
    left: 20px; bottom: 10px;
    @include transition-all(0.4s);
    .date {
      display: block;
      font-size: 12px;
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:before {
        content: '';
        position: absolute;
        left: 50%; bottom: 0;
        @include translate(-50%, 0);
        width: 30px; height: 1px;
        background-color: $border-c;
      }
    }
    .comments-qty {
      display: block;
      font-size: 10px;
    }
  }
  .btn-tr{
    background-color: $blue;
    border: 1px solid $blue;
    font-size: 0.6875rem;
  }

}

/* News - Article */
.article{
  .date_comments_location_author{
    margin-bottom: 20px;
  }
  .short-text{
    font-size: $font-size-h3;
    color: $blue;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .text-editor{padding-top: 20px;}

  .tag-list{;
    border-bottom: 1px dotted #999;
    padding:15px 0;
    margin-bottom: 20px;

    span{font-weight: bold;}

    span, .tags, li{display: inline-block;}

    li a{color: $color1-c;}

    li a:hover{color: $color2-c;}

  }
}
.news-category{
  .btn-tr {
    margin-top: 10px;
  }
}

