/* Services Baner - Home & Page Services List */

.services{
  margin-bottom: 40px;

  .text-block{


    .title-s{
      color: $brown;
      font-size: 15px; padding-bottom: 60px;

      &:after{bottom:30px; transform: scaleX(1); transition: transform .7s;}

      a{transition: .7s ease-in-out;}
    }
    .services-btn {
      width: 100%;
      text-align: center;
      height: 52px;
      line-height: 52px;
      font-size: 0.8125rem;
      background-color: #ECEDED;
      color: $black-c;
      text-transform: uppercase;
      font-weight: 600;
      @include transition-all(.4s);
      &:hover{
        @include transition-all(.4s);
        background-color: $blue;
        color: $white-c;
      }
    }
  }

  p{
    font-size: 0.9375rem;
    color: #777; text-align: center;
  }

  .service{
    margin-bottom: 30px;

    > a{
      padding: 0 0 20px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      height:274px;
      color: $brown;
      img{
        width: 100%;
        height: 100%;
      }
    }


    &:hover .title-s:after{transform: scaleX(1.5);}

  }

  .service:hover h3{
    color: $white-c;
    background-color: $blue;
    @include transition-all(.4s);
  }
}

.services-page{
  .services{
    margin: 0;
    @extend .clearfix;
  }

  .text-editor{
    margin-bottom: 30px;
    padding: 0 30px;
    text-align: center;
  }
}
