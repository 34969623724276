.social-links-holder {
  padding: 15px 0;
  border-top: 1px dotted #999;
  border-bottom: 1px dotted #999; font-size: 0;

  .block-title {
    font-weight: 700;
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    padding-right: 15px; font-size:  .9375rem;
  }
  .social-p {
    font-weight: 400;
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    padding-right: 5px;
    font-size: 0.875rem;
  }

  .social-links {
    display: inline-block;
    @extend .clearfix;

    li {
      display: inline-block;
      float: left;
    }

    a {
      display: block;
      line-height: 38px;
      width: 40px;
      border: 1px solid $border-c;
      color: #888;
      text-align: center;
      font-size: 1rem;

      &:hover {
        border-color: $blue;
        background-color: $blue;
        color: $white-c;
      }
    }
  }
}