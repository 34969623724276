#header {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #DDDDDD;

  section.top {
    background-color: $black-c;
    color: $white-c;
    position: relative;

    .contact {
      display: inline-block;
      > div {
        display: inline-block;
        border-right:1px solid $gray-c;
        padding: 6px 20px;
        a{
          color: $white-c;
          font-size: 0.8125rem;

          &:hover{
            opacity: 0.7;
          }
        }
        &:first-of-type {
          border-left:1px solid $gray-c;
        }
        &:hover{
          opacity: 0.7;
        }
      }
      .icon {
        display: inline-block;
        width: 18px; height: 38px;
        vertical-align: middle;
        text-align: center;
        line-height: 36px;
        margin-right: 10px;
      }
      .phone .icon{font-size: 19px;}
      .darker {
        color: #CBCBCB;
      }

      @include media-breakpoint-only(sm) {
        > div {
          display: block;
          line-height: 14px;

          .icon {
            // display: none;
          }
        }

        .email{display: none;}
      }

      @include media-breakpoint-only(xs) {
        .email{display: none;}
      }
    }

    .lang_social{
      float: right;

      > .languages {
        float: right;
        border-right:1px solid $gray-c;
        padding: 6px 20px;
        line-height: 26px;

        .c-drop-trigger{line-height: 38px;}

        a{
          text-transform: capitalize;
          font-size: 0.8125rem;

          span:first-of-type {
            padding-right: 7px;
          }
        }

        .c-drop-content {
          width: auto!important;
        }

        .content-holder{
          padding: 0 20px;
          top: 45px;
          text-align: left;
          border: 3px solid $gray-c;
          background-color: $white-c;
        }

        li{

          a{
            font-weight: 600;
            font-size: 0.875rem;
            text-transform: none;
            color: $black-c;
            position: relative;
            display: block;
            text-align: left;
          }

          &:hover a{
            color: $color2-c;
          }
        }
      }
      .social{
        float: right;
        font-size: 0;

        a{
          color: $white-c;
          border-right:1px solid $gray-c;
          padding:6px 20px;
          line-height:38px;
          font-size: 16px;
          display: inline-block;

          &:first-of-type {
            border-left:1px solid $gray-c;
          }

          &:hover{
            background: $blue;
          }
        }

      }
      @include media-breakpoint-down(md) {
        .social{display: none;}
      }
    }

  }

  section.main {
    font-size: 0;
    position: relative;

    .logo {
      float: left;
      margin: 20px 0;
    }

    .riht_main{
      float: right;
      padding: 20px 0;

      #search{
        float: right;
        padding: 12px 0;

        a.searchBtn{
          position: relative;

          span{
            font-size: 16px;
            width:35px; height: 35px;
            line-height: 35px;
            text-align: center;
            background: $color1-c;
            color: $white-c;

            &:hover{
              background: $color2-c;
            }
          }

        }
        .search {

          .content-holder{
            border:1px solid #ddd;
            padding: 0;
          }

          form {
            font-size: 0.8125rem;
            position: relative;

            &:after{
              font-family: FontAwesome;
              content: '\f002';
              position: absolute;
              right:0; bottom: 0;
              color: $white-c;
              font-size: 17px;
              z-index: -2;
              background-color: $blue;
              padding: 10px 15px;

            }

            input.inputbox{
              background: none;
              border: none;
              color: #545454;
              padding: 12px 44px 12px 10px;
              margin: 0;
              display: inline-block;
              width: 273px;

              &:focus{
                font-style: normal;
                color: $color1-c;
              }
            }

            input.button_search{
              width: 44px; height: 44px;
              background-color: transparent;
              color: $white-c;
              border: none;
              text-indent: -9999px;
              padding: 0;
              display: inline-block;
              position: absolute; right: 0; bottom: 0;
              cursor: pointer;
              z-index: 1;
            }
          }
        }
      }


    }





    @include media-breakpoint-down(md) {

      .logo {
        height: 40px;

        p {
          height: 100%;
        }

        img {
          max-height: 100%;
          width: auto;
        }
      }

      .riht_main{
        padding: 5px 0;

        .search {

        }

        .navigation{
          padding: 20px 15px;
        }
      }

    }

    @include media-breakpoint-only(xs) {
      .logo {
        flex-basis: 100%;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
      }
      .navigation {
        float: unset;
      }
      #search {
        float: unset;
        width: 100%;
        padding: 12px 0;
      }
      .riht_main {
        width: 100%;
      }
    }

  }

}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

//default style
.c-drop-holder {
  position: relative;
  .c-drop-content {
    position: absolute;
    right: 0; top: 110%;
    width: 360px;
    opacity: 0; visibility: hidden;
    z-index: -1;
    @include transition(opacity 0.4s, top 0.4s);
  }
  .c-drop-trigger {
    @extend .noselect;
    cursor: pointer;
  }

  &.drop-open {
    .c-drop-content {
      top: 100%;
      opacity: 1; visibility: visible;
      z-index: 1;
    }
  }

  @include media-breakpoint-down(xs) {
    position: static;
    .c-drop-content {
      width: 100%!important;
    }
  }
}

.c-drop-holder {
  .c-drop-content {

  }
  .c-drop-trigger {
    font-size: 0.875rem;
  }
}