
.newsletter-section {
  border-top: 1px solid #CCCCCC;
  padding: 30px 0;
  position: relative;
  .c-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .text{
      p{
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  #form_newsletter {
    padding: 20px;
    width: 760px;
    position: relative;

    .text-input{
      width: 50%;
    }

    .gcaptcha-submit{
      position: absolute;
      right: 0;
      width: 50%;
      top: 0;
      padding: 20px;
      box-sizing: border-box;
      height: 100%;

      .g-recaptcha{
        width: 50%;
        transform:scale(0.55);
        -webkit-transform:scale(0.55);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        display: inline-block;
        float: left;
      }
      .submit-box{
        width: 110px; float: left;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }

    .submit_box{/*width: 92px;*/  text-align: center }

    input[type="text"]{
      margin-top: 0;
      background: none transparent;
      padding: 10px 15px;
      border: 1px solid #F7F7F7;
    }

    input[type="submit"]{
      background: none;
      border: 1px solid $red-c;
      background-color: $red-c;
      color: $white-c;
      cursor: pointer; padding: 10px 15px;
    }
  }

  #newsleter_form_error{
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    font-weight: bold;
    left: 0;
  }
}

@include media-breakpoint-down(md) {
  .newsletter-section {

    #form_newsletter {
      width:730px;
      .gcaptcha-submit{
        .submit-box{
          float: right;
        }
      }
    }
  }

}
@include media-breakpoint-down(sm) {
  .newsletter-section {
    width: 100%;

    #form_newsletter {
      width:100%;

      .text-input{width:100%;}
      .gcaptcha-submit{
        width:100%;
        position: static;
        padding: 20px 0;
        margin-bottom: 24px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .newsletter-section #form_newsletter input[type="submit"] {
    z-index: 2;
    //background: $color2-c;
    position: relative;
    width: 84px;
  }
  .newsletter-section {

    #form_newsletter {
      width:730px;
      .gcaptcha-submit{
        .submit-box{
          width: 95px;
        }
      }
    }
  }
}