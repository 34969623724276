footer {
  background-color: $black-c;

  .footer-c{
    border-top:3px solid $color1-c;
  }

  img {
    margin-bottom: 15px;
  }

  .foo-block {
    padding-top: 20px;
    padding-bottom: 20px;
    border-right: 1px solid #DDD;
    padding-left: 20px;
    display: table-cell;
    float: none;
    height: 260px;
    width: 50%;
    vertical-align: top;

    &.contact{
      border-right: none;
    }

    .f_about_text{
      margin-bottom: 15px;
    }

    .title-f {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    ul {
      li {
        display: block;
        margin-bottom: 10px;
      }
      a {
        display: inline-block;
        position: relative;
        color: $black-c;

        &:hover {
          color: $color2-c
        }
      }
    }

    table {
      margin-top: -10px;
    }
    tbody > tr{
      td {
        width: 300px;
        padding-left: 28px;
        position: relative;
        font-size: 0.875rem;
        padding-top: 7px;
        padding-bottom: 7px;
        &:before {
          position: absolute;
          left: 0px; top: 50%;
          @include translate(0, -50%);
          font-size: 1rem;
          font-family: FontAwesome;
        }
      }

      &:nth-of-type(1) td:before {
        content: "\f041";
      }
      &:nth-of-type(2) td:before {
        content: "\f095";
      }
      &:nth-of-type(3) td:before {
        content: "\f0e0";
      }
      &:nth-of-type(4) td:before {
        content: "\f017";
      }
    }

    .social{
      border-top: 1px solid #dddddd;
      margin: 10px 0 0;
      padding-top: 10px;

      a{
        border-radius: 50%;
        width: 30px;
        height:30px;
        margin-right: 10px;
        background: $color1-c;
        color: #FFF;
        line-height: 30px; text-align: center;
        display: inline-block;

        &:hover{
          background: $color2-c;
          @include transition-all(.4s);
        }
      }
    }

  }

  .copyright {
    border-top: 1px solid #DDD;
    display: block;
    color: $white-c;
    font-size: 0.8125rem;
    font-weight: 400;
    padding: 20px 0 20px;

    .client{}
    .power{text-align: right;}

    .fa {
      color: $white-c;
      font-size: 1.375rem;
    }
    a {
      font-weight: 400;

      &:hover{
        color: $blue;
        text-decoration: underline;
        @include transition-all(.4s);
      }
    }

    @include media-breakpoint-down(md) {
      .client, .power {
        text-align: center;
      }
    }
  }
}


@include media-breakpoint-down(lg) {
  footer .foo-block{
    width:49%;
    display: inline-block;

    &:nth-child(2){
      border-right: none;
    }
  }
}


@media (max-width: 360px){
  footer .foo-block{
    width: 100%; height: auto; border-right: none; border-bottom: 1px solid #eeeeee;
  }
}