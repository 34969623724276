.product-preview-item {
  position: relative;
  border-bottom: 1px solid #DDDDDD;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px; left: 50%;
    @include translate(-50%, 0);
    width: 0; height: 1px;
    background-color: #4B4B4D;
    @include transition-all(0.4s);
  }

  &:hover {
    &:before {
      width: 100%;
    }
    .options-block {
      opacity: 1;
      bottom: 0;
    }
    .badges-block .bottom {
      bottom: 60px;
    }
    .images-holder.two-images {
      @include translate(-50%, 0);
    }
  }

  .image-block {
    position: relative;
    overflow: hidden;
  }

  .images-holder.two-images {
    width: 200%;
    position: relative;
    @include transition-all(0.4s);

    > div {
      width: 50%;
      float: left;
    }
  }

  .text-block {
    padding: 21px 10px;
    text-align: center;

    h3 {
      text-transform: uppercase;
      font-size: 0.8125rem;
      font-weight: 700;
      position: relative;
      a {
      }
    }

    .description {
      display: none;
      padding: 1.5rem 0;
    }

    .price-block {
      font-weight: 700;
      position: relative;
      height: 25px;

      .price-position-helper {
        position: absolute;
        width: 100%;
        top: 0;
      }

      .price {
        display: inline-block;
        font-size: 1rem;
        margin: 0 5px;
      }
      .old-price {
        display: inline-block;
        padding: 0 5px;
        position: relative;
        color: $red-c;
        &:before {
          content: '';
          position: absolute;
          left: 0; top: 50%;
          width: 100%; height: 1px;
          background-color: $red-c;
        }
      }
    }
  }

  .badges-block {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    pointer-events: none;
    > span {
      position: absolute;
      top: 10px;
      display: inline-block;
      width: 40px; height: 40px;
      border-radius: 50%;
      font-size: 0.75rem;
      color: $white-c;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }
    .new {
      background-color: $blue;
    }
    .action {
      background-color: $red-c;
    }
    .recommended {
      border: 2px solid $blue;
      color: $blue;
    }
    .left {
      left: 10px;
    }
    .right {
      right: 10px;
    }
    .bottom {
      right: 10px;
      top: auto;
      bottom: 10px;
      font-size: 1rem;
      @include transition-all(0.4s);
    }
  }

  .options-block {
    text-align: center;
    color: $white-c;
    background-color: rgba($white-c, 0.8);
    position: absolute;
    width: 100%;
    opacity: 0; bottom: -20px;
    @include transition-all(0.4s);
    ul {
      display: inline-block;
      li {
        height: 50px;
        width: 50px;
        display: inline-block;
        position: relative;
        a {
          display: block;
          line-height: 30px;
          padding: 10px 0;
          color: #4B4B4D;
          font-size: 1.0625rem;
          .only-list-view {
            display: none;
          }
        }
        .c-tooltip {
          position: absolute;
          opacity: 0; visibility: hidden;
          z-index: -1;
          left: 50%; bottom: 90%;
          @include translate(-50%, 0);
          padding: 9px 11px;
          color: $white-c;
          background-color: $blue;
          font-size: 0.625rem;
          font-weight: 700;
          text-transform: uppercase;
          display: inline-block;
          white-space: nowrap;
          @include transition-all(0.4s);
          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 8px 0 8px;
            border-color: $blue transparent transparent transparent;
            top: 100%; left: 50%; margin-left: -8px;
          }
        }
        &:hover {
          .c-tooltip {
            opacity: 1; visibility: visible;
            z-index: 1;
            bottom: 100%;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {

  .product-preview-item {
    &:before {
      width: 100%;
    }
    .image-block {
      padding-bottom: 50px;
    }

    .options-block {
      opacity: 1; bottom: 0;
      ul {
        display: inline-block;
        li:nth-of-type(2) {
          display: none;
        }
      }
    }
    .badges-block .bottom {
      bottom: 60px;
    }
  }
}

.touch {

  .product-preview-item {
    &:before {
      width: 100%;
    }
    .image-block {
      padding-bottom: 50px;
    }
    .options-block {
      opacity: 1;
      bottom: 0;
    }
    .badges-block .bottom {
      bottom: 60px;
    }
  }
}