.navigation {
  float: right;
  padding: 15px 0;
  .products{
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 0;
    background-color: $blue;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    a{
      color: $white-c;
      padding: 10px 10px;

    }
    &:hover,
    &.active{
      a{
        color: $white-c;
      }
      background-color: $red-c;
    }
  }

  nav > ul {
    margin: 0;

    li {
      list-style: none;
      position: relative;
    }
    .active > a, .onPath > a{
      color: $blue;
    }

    .active, .onPath{
      >.submenu-trigger:after{
        color: $blue;
        @include transition-all(0.4s);
      }
    }

    a {
      display: block;
    }

    > li {
      display: inline-block;
      float: left;
      margin-right: 20px;
      font-size: 0.8125rem;
      position: relative;
      text-transform: uppercase;



      &:hover{
        >.submenu-trigger:after{
          color: $blue;
          @include transition-all(0.4s);
        }
      }

      @include media-breakpoint-only(lg) {
        margin-right: 10px;
        a{
          font-size:  	0.6875rem;
        }
      }


      > a {
        padding: 10px 0;
        line-height: 20px;
        color: $text-c;
        font-weight: 600;
        display: inline-block;

        > span {
          color: $color2-c;
        }
      }

      // SUBMENU
      > ul {
        position: absolute;
        left: 0; top: 110%;
        opacity: 0; visibility: hidden;
        z-index: -1;
        /*white-space: nowrap;*/
        min-width: 160px;
        @include transition(opacity 0.4s, top 0.4s);

        > li{
          padding-right: 2px;

          .submenu-trigger:after{
            content: "\f054";
            position: absolute;
            right: 7px;
            top: 16px;
            font-weight: normal;
          }
          &:hover, &.active{
            >.submenu-trigger:after{
              color: $blue;
              @include transition-all(0.4s);
            }
          }
        }
      }
      &:hover {
        > a {
          color: $blue;
        }
        > ul {
          top: 100%;
          opacity: 1; visibility: visible;
          z-index: 1;
          background-color: #FFF;
          padding: 0;
          border-top: 1px solid #dddddd;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;

          &:before {
            content: "\f106";
            font-family: FontAwesome; font-size: 22px; line-height: 10px;
            color: #dddddd;
            display: inline-block;
            border-bottom: 1px solid #FFF;
            /*border-left: 5px solid rgba(0, 0, 0, 0.2);
            border-right: 5px solid rgba(0, 0, 0, 0.2);*/
            position: absolute;
            bottom: 100%; left: 20px;
          }

          li{border-bottom: 1px solid #dddddd;}

          a {
            //color: $white-c;
            padding: 10px 15px;

            &:hover {
              color: $blue;
            }
          }
        }
      }
    }

    > li li {
      position: relative;
      white-space: nowrap;
      > ul {
        background-color: #FFF;
        padding: 0;
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        min-width: 160px;
        border-top: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        @include transition(opacity 0.4s, margin-left 0.4s);

        &:before {
          content: "";
          position: absolute;
          top: 14px; left: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 7px 8px 0;
          border-color: transparent #FFFFFF transparent transparent;
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: -16px; top: 0;
          width: 16px; height: 100%;
        }
      }

      &:hover {
        > ul {
          margin-left: 10px;
          opacity: 1; visibility: visible;
          z-index: 1;
        }
      }
    }


    > li > li  {
      ul{
        opacity: 0; visibility: hidden;
      }
      &:hover ul {opacity: 1; visibility: visible;}
    }

  }
}


#responsive-menu-button {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  @include transform(rotate(0deg));
  @include transition-all(0.25s);
  cursor: pointer;


  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $blue;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    @include transition-all(0.25s)
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),span:nth-child(3) {
    top: 13px;
  }

  span:nth-child(4) {
    top: 26px;
  }
}

.sidr-animating,
.sidr-open {
  #responsive-menu-button {

    span:nth-child(1) {
      left: 50%; top: 13px;
      width: 0%;
    }

    span:nth-child(2) {
      top: 13px;
      @include transform(rotate(45deg));
    }

    span:nth-child(3) {
      top: 13px;
      @include transform(rotate(-45deg));
    }

    span:nth-child(4) {
      left: 50%; top: 13px;
      width: 0%;
    }
  }
}


#navigation {
  display: inline-block;
}

.fixed-header {
  padding-top: 67px;

  .navigation {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    z-index: 10;
    background-color: $white-c;

    border-top: 1px solid $gray-c;
    border-bottom: 1px solid $gray-c;
  }

  @include media-breakpoint-down(md) {
    padding-top: 52px;
    .navigation {
      baackground: $red-c;
    }
  }
}

@include media-breakpoint-up(md) {
  .fixed-header {
    .navigation {
      #responsive-menu-button {
        display: none;
      }

      #navigation {
        display: table;
        margin: 0 auto;
        padding: 0 5px;
      }
    }
  }
}
@include media-breakpoint-up(xl){
  .fixed-header {
    .navigation {
      #navigation {width: 1140px;}
    }
  }
}
@include media-breakpoint-down(lg){
  .fixed-header {
    .navigation {
      #navigation {width: 960px;}
    }
  }
}
@include media-breakpoint-only(md){
  .fixed-header {
    .navigation {
      #navigation {width: 720px;}
    }
  }
}



@include media-breakpoint-down(md){
  #responsive-menu-button {
    display: block; float: right;
  }
  #navigation {
    display: none;
  }
}


/* Right column menu navigation */
.sidebar-menu{
  ul{

    li{
      position: relative;

      a{
        padding: 10px 0 10px 20px;
        display: block;
        font-weight: bold;
        border-bottom: 1px solid #CCCCCC;

        &:before {
          content: '\f105';
          font-family: FontAwesome;
          display: inline-block;
          position: absolute;
          top: 10px; left: 10px;
          color: $color2-c;
        }
      }

      a:hover, &.active > a{
        color: $color2-c;
      }

      ul{
        padding-left: 10px;
        //display: none;

        li{

          a{
            border-bottom: 1px solid #DDDDDD;
            font-weight: normal;
          }
        }
      }

      //&.onPath ul{display: block;}

      &:last-child a{
        //border-bottom: none;
      }

    }
  }
}