.c-slider {
  position: relative;
}

.c-slider-arr {
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  cursor: pointer;
  z-index: 1;
  @include translate(0, -50%);
}

.tabs-slider {
  .c-slide-pos {
    padding: 0 15px;
  }
}

.tab-pane {
  display: none;
  @include transition-all(0.4s);
  &.active {
    display: block;
  }
}
//////////////////////////////
//HOMEPAGE SLIDER
//////////////////////////////
.homepage-slider {
  .c-slider-arr {
    color: $black-c !important;
    line-height: 44px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: rgba(255,255,255, 0.8);
    border: 1px solid rgba(255,255,255, 0.8);
    border-radius: 50%;
    span{
      color: $black-c;
    }
    &.prev-slide {
      left: 40px;
      //border-right: 4px solid;
    }
    &.next-slide {
      right: 40px;
      //border-left: 4px solid;
    }
    &:hover, span:hover{
      color: $white-c!important;
      background-color: rgba(85, 85, 85, 0.8);
      border: 1px solid rgba(85, 85, 85, 0.8);
    }
    &:hover span {
      color: $white-c !important;
      @include transition-all(.4s);
    }
  }

  .slick-slide {
    position: relative;
    overflow: hidden;
  }

  .text-block-holder {
    position: absolute;
    height: 100%; width: 100%;
    left: 0; top: 0;

    > .container {
      position: relative;
      height: 100%;
    }
  }

  .text-block {
    text-align: left;
    padding: 30px;
    display: inline-block;
    position: absolute;
    @include center(y);
    top:50%;
    left: 15px;
    opacity: 0;
    width: 49%;
    background-color: rgba(255,255,255,0.5);
    @include transition(opacity 2s 0.5s);
  }

  .text1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: $black-c;
    line-height: 1.2;
    text-transform: uppercase;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text2 {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.2;
    color: $black-c;
    padding-bottom: 20px;
    text-transform: uppercase;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text3 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: $white-c;
    padding-bottom: 28px;
    @include transition(opacity 2s 1s, left 2s);
  }

  a.btn-tr {
    border: 1px solid $red-c;
    background-color: $red-c;
    margin-bottom: 10px;
  }

  .slick-active {

    .text-block {
      opacity: 1;
    }

    .text1, .text2, .text3 {
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    .link-block a {
      opacity: 1;
      top: 0;
      z-index: 1;
    }
  }
}

.homepage-slider {
  height: 540px;

  .slick-slide {
    height: 540px;
  }
}

@include media-breakpoint-down(md) {
  .homepage-slider {
    .text-block {top:50%; width: 80%;}
    .c-slider-arr.prev-slide { left: 5px; }
    .c-slider-arr.next-slide { right: 5px; }
  }
}

@include media-breakpoint-between(md, lg) {
  .homepage-slider {

    .text-block {
      padding: 20px 10px;
    }

    .text1 {
      font-size: 12px;
    }

    .text2 {
      font-size: 36px;
    }
  }

  .homepage-slider {
    height: 400px;

    .slick-slide {
      height: 400px;
    }
  }
}

@include media-breakpoint-only(sm) {
  .homepage-slider {

    .text-block {
      padding: 15px 10px;
    }

    .text1 {
      font-size: 12px;
    }

    .text2 {
      font-size: 24px;
    }
  }

  .homepage-slider {
    height: 300px;

    .slick-slide {
      height: 300px;
    }
  }
}

@include media-breakpoint-only(xs) {
  .homepage-slider {

    .c-slider-arr {
      display: none!important;
    }

    .text-block {
      padding: 15px 10px;
      left: 50%;
      @include translate(-50%, -50%)
    }

    .text1 {
      font-size: 11px;
      margin-top: 5px;
    }

    .text2 {
      font-size: 18px;
    }

    .link-block a {
      line-height: 15px;
      padding: 3px 10px;
      font-size: 10px;
    }
  }

  .homepage-slider {
    height: 250px;

    .slick-slide {
      height: 250px;
    }
  }
}
.products-slider-section {
  padding-top: 50px;
  padding-bottom: 100px;
  border-bottom: 1px solid $border-c;
}

.products-slider{
  margin-top: 50px;

  /* the slides */
  .slick-slide {
    margin: 0 10px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }


  .c-slider-arr {
    top: -40px;
    right: 20px;
    color: #4B4B4D !important;
    border-color: #4B4B4D;
    line-height: 20px; width: 30px;
    font-size: 20px;
    @include translate(0, 0);
    &.prev-slide {
      margin-left: -30px;
      &:before {
        content: '';
        position: absolute;
        right: -1px; top: 0;
        height: 20px; width: 2px;
        background-color: #4B4B4D;
      }
    }
    &.next-slide {
      right: -10px;
    }
    &:hover {
      color: $blue!important;
    }
  }
}

//////////////////////////////
// LATEST NEWS HOME SLIDER
//////////////////////////////
.news-homepage{
  /* the slides */
  .slick-slide {
    margin: 0 10px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }
  .c-slider-arr {
    top: -80px;
    left: 100%;
    color: #4B4B4D !important;
    border-color: #4B4B4D;
    line-height: 20px; width: 30px;
    font-size: 24px;
    @include translate(0, 0);
    &.prev-slide {
      margin-left: -30px;
      &:before {
        content: '';
        position: absolute;
        right: -1px; top: 0;
        height: 25px; width: 2px;
        background-color: #4B4B4D;
      }
    }
    &.next-slide {
      right: 0;
    }
    &:hover {
      color: $blue!important;
    }
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }
  .slick-slide {
    img{
      width: 100%;
    }

  }
}
.slider{
  /* the slides */
  .slick-slide {
    margin: 0 10px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }
}