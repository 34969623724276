.product-page {
  .products-slider-section {
    padding-top: 30px;
    padding-bottom: 50px;
    border-bottom: unset;
    .section-title-t1{
      font-size: 2.0625rem;
      text-transform: unset;
      span{
        padding: 0 30px;
        &:hover{
          color: unset;
        }
        &:before,
        &:after{
          background-color: #DDDDDD;
        }
      }
    }
  }
}

.product-options {

  .block-title {
    display: block;
    line-height: 40px;
    margin-right: 10px;
  }

  .product-header {

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #DDDDDD;
    }
    .brend-block {

      .price {
        display: inline-block;
        font-size: 0.9375rem;
        margin: 0;
        a{
          color: $red-c;
          text-decoration: underline;
        }
      }

      .old-price {
        display: inline-block;
        padding: 0 5px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0; top: 50%;
          width: 100%; height: 1px;
          background-color: $color2-c;
        }
      }
    }

    .description {
      padding: 10px 0 20px 0;
      margin-top: 12px;
      border-bottom: 1px solid #dddddd;

      p {
        margin-bottom: 0;
      }
    }
    .video_box {
      padding: 25px 0;
      i{
        font-size: 19px;
        color: $red-c;
        margin-right: 10px;
      }
      a{
        color: #333333;
        font-size: 0.9375rem;
        font-weight: 600;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  .product-cart {
    //border-bottom: 1px solid $gray-c;

    .selectboxes-holder {
      .select {
        margin-bottom: 1.25rem;
      }
    }

    .product-links-holder {

      ul{

        li {
          float: left;
          margin-right: 10px;

          &:last-child{
            margin-right: 0;
          }

          a {
            display: inline-block;
            text-decoration: none;
            padding-left: 20px;
            //position: relative;
            line-height: 40px;
            color: $blue;
            font-weight: 500;

            span{
              position: absolute;
              left: 7px;
              top: 1px;
              font-size: 1rem;
              height: 40px;
              width: 40px;
              text-align: center;
              line-height: 38px;
              display: inline-block;
              vertical-align: middle;
              @include transition-all(0.4s);
            }
          }
        }
      }
    }
    .product-social {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      @include media-breakpoint-down(sm){
        flex-direction: column;
        align-items: center;
      }
    }
  }


  //padding: 1.25rem 0;
  //border-bottom: 1px solid $gray-c;

  .categories-holder {

    a {
      display: inline-block;

      &:hover {
        color: $color1-c;
      }
    }
  }
  .product-btn {
    width: 260px;
    height: 40px;
    line-height: 40px;
    background-color: $red-c;
    text-align: center;
    margin: 15px 0;
    a{
      color: $white-c;
      font-weight: 600;
    }
  }

  .social-links-holder {
    border:unset;
    display: flex;
    @include media-breakpoint-down(sm){
      padding-bottom: 1.25rem;
      margin-top: 0;
    }

    .social-links {
      display: block;
      @extend .clearfix;

      li {
        display: inline-block;
        float: left;
        margin: 0 2px;
      }

      a {
        display: block;
        line-height: 38px;
        width: 40px;
        border: 1px solid #DDDDDD;
        color: #545454;
        text-align: center;
        font-size: 1rem;
        background-color: #F7F7F7;
        border-radius: 5px;

        &:hover {
          border-color: $red-c;
          background-color: $red-c;
          color: #F7F7F7;
        }
      }
    }
  }

}

.product-description{
  table{
    margin: 30px 0;
    border-color: #DDDDDD;
    thead {
      background-color: #EEE;
      height: 40px;
      tr{
        th {
          text-align: left;
          padding: 0 30px;
          font-size: 1.125rem;
          font-weight: 600;
          border-color: #DDDDDD;
        }
      }
    }
    tbody {
      tr{
        td{
          padding: 30px;
          border-color: #dddddd;
          ul{
            li{
              position: relative;
              &:before{
                content: "";
                position: absolute;
                top: 8px; left: -22px;
                width: 4px; height: 4px;
                background-color: $red-c;
                border-radius: 50%;
              }
              &:after{
                content: "";
                position: absolute;
                top: 8px; left: -14px;
                width: 4px; height: 4px;
                background-color: $red-c;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}
.send-to-friend {
  text-align: left;

  .c-textarea {
    min-height: 120px;
  }
}

.modal-send-to-friend {
  .close {
    position: absolute;
    right: -17px;
    top: -17px;
    width: 45px;
    height: 45px;
    text-indent: -99999px;
    opacity: .85;
    background: url('../images/close-quick.png');
    border: none;
    cursor: pointer;
  }
}

.counter-holder{
  position: relative;
  padding: 0 0 0 137px;

  & .product-counter{
    border: 1px solid $gray-c;
    padding-right: 30px;
    position: absolute;
    left: 0; top: 0;
    display: inline-block;
    width: 122px;

    input{
      border: 0 none;
      float: left;
      line-height: 25px;
      padding: 11px 0 12px 0;
      text-align: center;
      width: 97px;
    }

    div{
      border-left: 1px solid $gray-c;
      cursor: pointer;
      font-size: 15px;
      line-height: 100%;
      padding: 5px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 27px;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      background-color: $color2-c;
      color: $white-c;
      &:hover{
        background-color: $color1-c;
      }
      &.addQuantity{
        border-bottom: 1px solid $gray-c;
        top: 0;
      }
      &.minusQuantity{
        bottom: 0;
      }

    }
  }

  .large-button{
    font-size: 0.8125rem;
    text-transform: uppercase;
    color: $white-c;
    background-color: $green-c;
    text-align: center;
    line-height: 33px;
    display: block;
    width: 100%;
    height: 50px;
    border: 1px solid $gray-c;
    cursor: pointer;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    font-weight: 700;
    letter-spacing: 1px;
    @include transition-all(0.4s);
    &:hover{
      background-color: $blue;
      color: $white-c;
    }
  }

}

@include media-breakpoint-down(md) {
  .product-options {
    margin-top: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .best-sellers {
    margin-top: 30px;

    .text-block {
      padding-right: 10px;
    }
  }
}

.product-category-page {

  .sidebar-block {
    margin-bottom: 50px;
    @include media-breakpoint-only(xl) {
      width: 100%;
    }
    .block-title {
      background-color: $blue;
      padding: 7px 10px;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;
      text-transform: uppercase;
      position: relative;
      i{
        color: $white-c;
        position: absolute;
        top: 50%;
        bottom: auto;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 10px;
        font-weight: 700;
        font-size: 1.5625rem;
      }
    }

    .category-title {
      background-color: $blue;
      padding: 9px 20px;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 0;
    }
    .recent-blog-single{
      padding: 8px 0;
      border-bottom: 1px solid #ddd;
    }
    .text-block {
      display: flex;
      justify-content: space-between;
      padding:0 20px;
      p{
        font-weight: 400;
        margin: 0;
        font-size: 1rem;
        text-transform: capitalize;
      }
    }
  }
    .service{
      max-width: 320px;
      margin: 20px auto;

      > a{
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        height:230px;
        color: $brown;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .text-block{
        display: block;
        padding: 0;
        .title-s{
          color: $brown;
          font-size: 15px; padding-bottom: 60px;

          &:after{bottom:30px; transform: scaleX(1); transition: transform .7s;}

          a{transition: .7s ease-in-out;}
        }
        .services-btn {
          width: 100%;
          text-align: center;
          min-height: 45px;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 0.9375rem;
          background-color: #E2001A;
          color: $white-c;
          text-transform: uppercase;
          font-weight: 600;
          @include transition-all(.4s);
          &:hover{
            @include transition-all(.4s);
            background-color: $blue;
            color: $white-c;
          }
        }
      }


      &:hover .title-s:after{transform: scaleX(1.5);}

    }

    .service:hover h3{
      color: $white-c;
      background-color: $blue;
      @include transition-all(.4s);
    }
  }
.product-brend-title{
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  h4 {
    font-weight: 600;
    position: relative;
    padding: 0 40px 15px 0;
    cursor: pointer;
    line-height: 30px;
    font-size: 1.5rem;
    margin: 0;
    color: #4B4B4C;
    text-transform: uppercase;
    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 29px;
      height: 30px;
      display: inline-block;
      padding: 0;

      //    border-bottom: 1px solid $white-c;
      z-index: 1;
      &:after {
        content: '\f107';
        font-family: FontAwesome;
        position: absolute;
        right: 7px;
        top: 5px !important;
        @include center(y);
        @include transform(rotate(0deg));
        color: #8797B4;
        margin-top: -1px;
        font-size: 30px;
        font-weight: 900;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(180deg));
          @include transition-all(0.4s);
        }
      }
    }
  }
  .checkboxes-holder {
    padding: 10px 0;
  }
  .img-text{
    p{
      margin-bottom: 5px;
      font-size: 1rem;
    }
    a{
      color: $red-c;
      font-weight: 700;
    }
  }
}
.sidebar-filters {
  border-bottom: 1px solid $gray-c;
  padding-bottom: 12px;

  h4 {
    font-weight: 400;
    position: relative;
    padding: 10px 30px 10px 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 30px;
    font-size: 0.875rem;
    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 0; top: 10px;
      width: 25px;
      height: 25px;
      display: inline-block;
      padding: 0;
      //    border-bottom: 1px solid $white-c;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        width: 10px; height: 2px;
        right: 7px;
        @include center(y);
        background-color: $black-c;
      }
      &:after {
        content: '';
        position: absolute;
        width: 10px; height: 2px;
        right: 7px;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $black-c;
        margin-top: -1px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }
  }
  .checkboxes-holder {
    padding: 5px 10px 15px 10px;
    label {
      text-align: left!important;
    }
  }
  label {
    text-align: left!important;
    padding: 0 0 0 30px!important;
    margin-bottom: 8px!important;
    font-size: 1rem;
  }
}


.sidebar-filters-options {
  a {
    display: block;
    font-size: 0.875rem;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 0; padding-right: 0;
  }
  .btn-t1{
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    height: 40px;
    line-height: 38px;
    color: $black-c !important;
    width: 108px;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0;
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
    &.green {
      background-color: $green-c;
      color: $white-c !important;
      border-color: $green-c;
      &.ghost {
        background-color: transparent;
        color: $color1-c;
        border-color: $color1-c;
        &:hover {
          color: $green-c!important;
        }
      }
    }
  }
}

/* Categories */
.categories-block{
  position: relative;
  z-index: 1;
  margin-top: 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  > ul {
    //border-top: 1px solid $gray-c;
  }

  li{
    position: relative;
    display: block;
    border-bottom: 1px solid #DDDDDD;
  }

  a{
    display: block;
    padding: 12px 10px;
    line-height: 20px;
    font-size: 0.8125rem;
    color: #4B4B4C;
    position: relative;
    text-transform: uppercase;
    &:hover,
    &:focus{
      color: $blue !important;
      text-decoration: none;
    }
  }

  .active > a {
    background-color: $color1-c;
    color: $white-c;
    &:hover,
    &:focus{
      background-color: $color1-c;
      color: $white-c;
    }
  }

  i{
    color: #4B4B4C;
    position: absolute;
    @include center(y);
    right: 15px;
    font-weight: 700;
    font-size: 1.0625rem;
  }

  ul > li {

    > ul {
      position: absolute; z-index: -1;
      left: 100%; top: 100%;
      width: 270px;
      background-color: #fff;
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.65);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.65);
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.65);
      min-height: 0px;
      //overflow: hidden;
      padding: 10px 15px;
      opacity: 0;
      height: 0;
      visibility: hidden;
      @include transition-all(0.4s);
    }

    &:hover {
      > ul {
        z-index: 1;
        opacity: 1;
        top: 0;
        visibility: visible;
        height: auto;
      }
    }
  }

}

.view-type {
  display: block;
  text-align: right;

  li {
    display: inline-block;
    width: 40px; height: 40px;
    font-size: 20px;

    a {
      line-height: 38px;
      display: block;
      text-align: center;
      border: 1px solid $border-c;
      &.active{
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }

    &.view-grid {
      margin-right: 6px;
    }
  }
}

.product-category-list-options {
  @extend .clearfix;
  margin-bottom: 40px;

  @include media-breakpoint-only(xl) {
    .pos-helper {
      position: relative;

      .view-type-holder {
        width: 90px;
        position: absolute;
        right: -105px; top: 0;
        padding: 0;
      }
    }
  }

  @include media-breakpoint-between(sm, lg) {
    .pos-helper {
      > div .row {
        margin-top: 20px;
      }
    }
  }

  .border {
    @extend .clearfix;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-c;
  }

  @include media-breakpoint-down(xs) {
    .select {
      margin-bottom: 20px;
    }
  }
}

.product-category-list {

  > div {
    @include media-breakpoint-down(md) {
      &:nth-of-type(2n+1) {
        clear: both;
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-of-type(3n+1) {
        clear: both;
      }
    }
  }


  .product-preview-item {
    margin-bottom: 30px;
  }

  &.list-view {

    > div {
      width: 100%;
    }

    .product-preview-item {
      position: relative;
      border-bottom: 0;
      padding-left: 300px;

      &:before {
        display: none;
      }

      &:hover {
        .text-block:before {
          width: 100%;
        }
      }

      .image-block {
        position: absolute;
        left: 0; top: 0;
        width: 270px;
      }

      .text-block {
        padding: 0;
        text-align: left;
        min-height: 360px;
        padding-bottom: 90px;
        position: relative;
        border-bottom: 1px solid $border-c;

        &:before {
          content: '';
          position: absolute;
          bottom: -1px; left: 50%;
          @include translate(-50%, 0);
          width: 0; height: 1px;
          background-color: $color2-c;
          @include transition-all(0.4s);
        }

        h3 {
          text-transform: uppercase;
          font-size: 1.25rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 700;
          margin-bottom: 5px;
          a {

          }
        }

        .description {
          display: block;
        }

        .price-block {
          .price {
            margin: 0 5px 0 0;
          }
        }
      }

      .options-block {
        text-align: left;
        color: $gray;
        background-color: transparent;
        position: absolute;
        width: 100%;
        opacity: 1; bottom: 20px;

        ul {
          background: transparent;

          > li {
            height: 50px;
            width: auto;
            display: inline-block;
            position: relative;
            margin-right: 10px;
            border: 1px solid $gray-c;

            &:nth-of-type(1) .c-tooltip {
              display: none!important;
            }

            &:nth-of-type(2) {
              display: none;
            }

            a {
              display: block;
              line-height: 28px;
              padding: 10px 15px;
              color: #888;
              font-size: 1.25rem;
              .only-list-view {
                font-size: 0.875rem;
                display: inline-block;
              }

              &:hover {
                background-color: $color2-c;
                border-color: $color2-c;
                color: $white-c;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-only(lg) {

      .product-preview-item {
        padding-left: 240px;

        .image-block {
          width: 210px;
        }

        .text-block {
          min-height: 280px;
        }
      }
    }

    @include media-breakpoint-between(sm, md) {

      .product-preview-item {
        padding-left: 180px;

        .image-block {
          width: 150px;
        }

        .text-block {
          min-height: 200px;
        }
      }
    }

    @include media-breakpoint-only(xs) {

      .product-preview-item {
        padding-left: 0;

        .image-block {
          position: relative;
          display: block;
          width: 210px;
          margin: 0 auto;
        }

        .text-block {
          min-height: 1px;
          margin-top: 1rem;
          padding-bottom: 10px;
        }
        .options-block {
          position: relative;
          bottom: 0;
        }
      }
    }
  }
}
.check-group{

  .checkbox-left{
    position: relative;
    input{
      position: absolute;
      left: 0;
      top: 3px;
      width: 15px;
      height: 15px;
      background-color: #EEE;
    }
  }

}
