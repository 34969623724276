/* Home page custom css */

/* HOME BLOCK 1 */
.h-block-1{
  .norme{font-size: 0; text-align: center;

    img{display: inline-block; width: 370px;}
  }
}

/* HOME ABOUT US */
.h_about_us{

  position: relative;

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(70%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .text-block{
    text-align: right;
    h3{
      font-size: 1.125rem;
      text-transform: uppercase;
      font-weight: 700;

      &:after{background: #ECEDED;}
    }
  }
  .text-editor{
    padding-bottom: 25px;
    h2{
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: 700;
    }
    p{
      font-size: 2.8125rem;
      text-transform: lowercase;
      font-weight: 700;
    }
  }
  a.btn-tr{
    background-color: $red-c;
    border: 1px solid $red-c;
  }

}

/* HOME BLOCK 2 */
.h_block_2{
  background: #EEEEEE;
  padding: 102px 0;
  margin-bottom: 45px;

  .col-md-4{
    display: flex;

    .icon{
      display: inline-block;
      width: 90px;
    }

    .text-block{
      display: inline-block;
      width: 72%;

      .title-b2{
        color: $color1-c;
        font-weight: bold;
        padding-bottom: 25px;
      }

      p{padding-bottom: 20px;}
    }
  }
}

@include media-breakpoint-down(md) {
  .h_about_us::before {background-image: none !important;}
  /* HOME ABOUT US */
  .h_about_us{
    .text-block{
      h3{
        font-size: 1rem;
      }
    }
    .text-editor{
      h2{
        font-size: 3rem;
      }
      p{
        font-size: 2rem;
      }
    }
  }
  .h_block_2{
    padding: 40px 0;
    .col-md-4{
      padding-bottom: 40px;

      .icon{width: 70px;}
    }
  }
}

/* HOME BLOCK 3 */
.h_block_3{
  margin-bottom: 80px;

  .text-block{
    h2{
      font-weight: bold;
      color: $color1-c;
      font-size: 2.5rem;
    }

    h3{
      color: $color1-c;
      font-weight: bold;
      font-size: 1.25rem;
    }

    ul{
      padding-left: 15px;
      margin-left: 7px;

      li{
        position: relative;

        &:before{
          content: "";
          position: absolute;
          top: 7px;
          left: -15px;
          width: 5px;
          height: 5px;
          background-color: $color2-c;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-dots{
    width: auto;
    text-align: center;
    padding: 20px 0;

    li{
      display: inline-block;
      font-size: 0;

      button{
        border: 1px solid #555;
        border-radius: 50%;
        background: none;
        width: 15px; height: 16px;
        margin: 0 5px;

        &:hover{background: #555;}
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .h_block_3{
    .text-block{
      td{width: 100% !important; height: auto; display: block}
    }
  }
}